<template>
  <v-app class="home">
    <section id="intro">
      <div class="into">
        <v-container class="header">
          <v-row class="header-position"><h3 class="myname">eri2490</h3></v-row>
          <v-row><div class="line" /></v-row>
        </v-container>
        <div class="backstretch"></div>
      </div>
    </section>
    <section id="aboutme">
      <v-container class="mt-15">
        <v-row>
          <v-col cols="1"><span class='title-emoji'>👒</span></v-col>
          <v-col cols="9" class='section-title'>About me: 西倉 愛理</v-col>
        </v-row>
      </v-container>
      <v-container class="mt-15">
        <v-row>
          <v-col cols="5"><v-img v-bind:src="image_src" class="myicon"></v-img></v-col>
          <v-col cols="6">
            インターネット広告系企業で働くエンジニアです。<br/>
            アルゴリズムを考えるのが好きでAtCoderのABCに毎週参加しています💻
            <span class="sns">
              <a href="https://twitter.com/eri2490" target="_blank" rel="noopener noreferrer">
                <v-btn icon><v-icon>fab fa-twitter</v-icon></v-btn>
              </a>
            </span>
          </v-col>
        </v-row>
      </v-container>
      <div class="abt-backstretch"></div>
    </section>
    <section id="experience">
      <v-container class="mt-15">
        <v-row>
          <v-col cols="1"><span class='title-emoji'>🧾</span></v-col>
          <v-col cols="9" class='section-title'>Detail</v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12">
            教育学部卒業。大学時代、卒業後はデザイナーとしてWebサイト制作に従事。<br />
            UIデザインのトレンドの変化を感じた時、更にインターネットのことが知りたくなりサーバーの学習を開始。<br />
            現在は<span class="accent_color">Vue.js</span>を用いたフロントエンドの開発・運用、
            <span class="accent_color">Python</span>を使用したクローラーの開発、
            <span class="accent_color">Ruby on Rails</span>を使用したAPIを設計・開発・運用を行っている。           
          </v-col>
          <v-col cols="12">
            趣味ではアルゴリズムを早く正確に書けるように競技プログラミングに取り組み中。
          </v-col>
          <v-col cols="12">
            得意なスキル<br />
            <span class="accent_color">Ruby on Rails</span>, <span class="accent_color">Python</span>, <span class="accent_color">Vue.js</span>
          </v-col>
          <v-col cols="12">
            これまで業務で使用した言語・フレームワーク<br />
            <span class="accent_color">Ruby on Jets, Electron, Flask</span><br />
            認証にはauth0を組み込んだ経験あり。<br />
          </v-col>
          <v-col cols="12">
            インフラ<span class="accent_color">AWS(S3, EC2, Cognito)</span>を業務で使用中。<br />
          </v-col>
          <v-col cols="12">リラックス方法は音楽を聴くこと・写真・読書・料理🍳🍶</v-col>
        </v-row>
      </v-container>
      <div class="dtl-backstretch"></div>
    </section>
    <section id="ecudation">
      <v-container class="mt-15">
        <v-row>
          <v-col cols="1"><span class='title-emoji'>🔗</span></v-col>
          <v-col cols="9" class='section-title'>Accounts</v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-list>
          <v-list-item-group>
            <v-list-item 
              v-for="(item, i) in items" 
              :key="i" 
              :href="item.href"
              target="_blank"
              rel="noopener noreferrer"
            >
              <v-list-item-icon>
                <v-icon color="item.icon_color">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-container>
    </section>
    <section id="portfolio">
      <v-container class="mt-15">
        <v-row>
          <v-col cols="1"><span class='title-emoji'>🎯</span></v-col>
          <v-col cols="9" class='section-title'>この先やりたいこと</v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row class="pt-12">
          <v-col cols="12" color="primary" light>AtCoderで水色コーダーになる</v-col>
          <v-col cols="12" color="primary" light>人々の文化・生活・価値観を少し豊かにするようなプロダクトを作る</v-col>
          <v-col cols="12" color="primary" light>技術や自分の生き方を通じてジェンダーギャップを埋める</v-col>
        </v-row>
      </v-container>
    </section>
    <copyrigt></copyrigt>
  </v-app>
</template>

<script>
import '@/sass/main.scss'
import copyrigt from '@/components/Footer.vue'
//import axios from 'axios'

export default {
  name: 'Home',
  data() {
    return {
      image_src: require("@/assets/me.jpg"),
      items: [
        { text: 'Twitter', href:'https://twitter.com/eri2490', icon: 'fab fa-twitter', icon_color: 'green' },
        { text: 'Github', href:'https://github.com/eri2490', icon: 'fab fa-github' },
        { text: 'Qiita', href:'https://qiita.com/eri2490', icon: 'fas fa-file-code' },
        { text: 'Blog(技術用)', href:'https://eri2490.hatenablog.com/', icon: 'far fa-file-alt' },
        { text: 'note(ポエム用)', href:'https://note.com/eri2490', icon: 'fas fa-align-left' },
        { text: 'Instagram', href:'https://www.instagram.com/ellie_2aqo/', icon: 'fab fa-instagram' },
        { text: 'Dispo: @eri', href:'', icon: 'fas fa-camera-retro', selectable: false },        
      ]
    }
  },
  components: {
    copyrigt
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang='scss' scoped>
/******* 
 ALL 
*******/
.accent_color{
  color: #ff7272;
}
a {
  text-decoration: none;
}
.section-title{
  margin-top: 1.2rem;
  position: relative;
  top: 152px;
  left: 5%;
  font-size: 62px;
  font-weight: 500;
  color: #5b9bbc;
  height: 100%;
  z-index: 1;
}
.title-emoji{
  float: left;
  font-size: 175px;
}
/******* 
 intro 
*******/
#intro{
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #dcdcdc;
  color: #fff;
}
.info{
  width: 100%;
  height: 100%;
  margin-bottom: -60px;
  text-align: center;
}
.header{
  height: 760px;
  max-width: 100%;
  margin: 0;
  padding: 0;
}
.header-position{
  position: relative;
  top: 282px;
}
.myname{
  margin: 0 auto;
  font-size: 117px;
  font-weight: 500;
  padding: 0;
}
.line{
  display: block;
  width: 390px;
  height: 3px;
  padding: 0!important;
  background-color: #5b9bbc;
}

/******* 
 about me 
*******/
.myicon{
  height: 200px;
  max-with: 100%;
}
.abt-backstretch{
  margin: 0;
  padding: 0;
  height: 119px;
  width: 1073px;
  z-index: -999998;
}
.sns{
  display: block;    
  padding-top: 15px;
}
.sns a .v-icon{
  color: #1da1f2;
}
/******* 
 Detail
*******/
.dtl-backstretch{
  margin: 0;
  padding: 0;
  height: 119px;
  width: 1073px;
  z-index: -999998;
}
/******* 
 Education
*******/
.v-list-item__content{
  padding: 3px 0;
}
.v-list-item .v-list-item__title{
  line-height: 1.8;
}
</style>

