<template>
  <div class="footer">  
    <footer>Copyright © 2021 eir2490</footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer{
  font-size: 11px;
  text-align: right;
  margin: 13px;
}
</style>
